*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

:root{
  --red: #b41615;
  --light-orange: #fc9327;
  --brown: #c95a17;
  --light-yellow: #fef48c;
  --dark-brown: #33111a;
  --medium-yellow: #f8cd5c;
  --cream: #fce4cf;
  --blue: #3827a2;
  --background: #db5151;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

th {
  background-color: #4287f5;
  color: white;
}

th,
td {
  width: 150px;
  text-align: center;
  border: 1px solid black;
  padding: 5px;
}

h2 {
  color: #4287f5;
}

.dynamic{
  background-color: var(--background);
  overflow: hidden;
}

.main{
  width: 100%;
  height: 100vh;
  position: relative;
}

.main__image{
  position: absolute;
  width: 100%;
  height: 100%;
}

.main__image img{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.main__content{
  text-align: center;
  color: var(--dark-brown);
  text-transform: uppercase;
  position: absolute;
  top: 10%;
  right: 5%;
}

.main__content-sub{
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.835em;
}

.main__content-main{
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 1;
  text-shadow: 0 0 5px rgba(0,0,0,0.2);
}

/* Media Queries  */
@media screen and (max-width: 740px){

  .main__content{
      right: 0;
      left: 50%;
      transform: translateX(-50%);
  }

  .main__content-main{
      font-size: 2.5rem;
  }

  .main__content-sub{
      font-size: 1rem;
  }
}

@media screen and (max-width: 660px){

  .main__content{
      position: relative;
      top: 25%;
      right: 0;
  }
}
